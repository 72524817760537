import _ from 'lodash';
import { v4 } from 'uuid';
import { Injectable } from '@angular/core';
import { INavigation, INavInfo, INavMenu } from '@shared/models';

@Injectable()
export class NavigationMenuService {
	constructor() {}

	/**
	 * *Initialing navigation for sidebar
	 *
	 * @param navigation
	 * @returns INavMenu[]
	 * @date 21 January 2023
	 * @developer Rahul Kundu
	 */
	public initNavigation(navigation: INavigation[]): INavMenu[] {
		const filteredMenus: INavMenu[] = [];

		const filteredNavigation = navigation
			.filter(
				(nav) =>
					nav.name !== 'Hub' &&
					nav.name !== 'Batch' &&
					nav.name !== 'Merchant User'
			)
			.reduce((accu: INavMenu[], curr, index, { [index + 1]: eNext }) => {
				let name = curr.name.toLowerCase().replace(/ /g, '-');
				let { icon, label, module, routerLink } =
					this.getMenuIconURL(name);
				const hasSubMenu =
					!!curr.sub_navigation_menu &&
					!!curr.sub_navigation_menu.length;

				const menuItem: INavMenu = {
					icon,
					label,
					module,
					id: v4(),
					...(!hasSubMenu && {
						routerLink
					})
				};

				if (hasSubMenu) {
					menuItem.items = curr.sub_navigation_menu
						.filter((subNav) => subNav.name !== 'Support Login')
						.map((subNav) => {
							name = subNav.name.toLowerCase().replace(/ /g, '-');
							({ label, module, routerLink } =
								this.getMenuIconURL(name));

							return {
								label,
								module,
								id: v4(),
								routerLink: routerLink
							};
						});
				}

				if (curr.name === 'Role') {
					filteredMenus.push(menuItem);
				} else {
					accu.push(menuItem);
				}

				if (!eNext) {
					accu.splice(5, 0, ...filteredMenus);
				}

				return accu;
			}, []);

		const uniqueNavigation = _.uniqBy(filteredNavigation, 'label').map(
			(item) => {
				if (item.items) {
					item.items = _.uniqBy(item.items, 'label');
				}
				return item;
			}
		);

		return uniqueNavigation;
	}

	/**
	 * *Generating menu icon, label, url
	 *
	 * @param label
	 * @returns navmenu icon, label, url
	 * @date 21 January 2023
	 * @developer Rahul Kundu
	 */
	public getMenuIconURL(label: string): INavInfo {
		const navInfo: INavInfo = Object.create(null);

		switch (label) {
			case 'dashboard':
				navInfo['label'] = 'dashboard';
				navInfo['module'] = 'Dashboard';
				navInfo['icon'] = 'icon-dashboard';
				navInfo['routerLink'] = '/dashboard';
				break;
			case 'orders':
				navInfo['label'] = 'orders';
				navInfo['module'] = 'Orders';
				navInfo['icon'] = 'icon-orders';
				navInfo['routerLink'] = '/orders';
				break;
			case 'live-order':
				navInfo['label'] = 'live orders';
				navInfo['module'] = 'Live Order';
				navInfo['icon'] = 'icon-live-orders';
				navInfo['routerLink'] = '/live-orders';
				break;
			case 'restaurants':
				navInfo['label'] = 'restaurants';
				navInfo['module'] = 'Restaurants';
				navInfo['icon'] = 'icon-restaurant';
				navInfo['routerLink'] = '/restaurants';
				break;
			case 'restaurants-menu':
				navInfo['label'] = 'menus';
				navInfo['module'] = 'Menus';
				navInfo['routerLink'] = '/menus';
				break;
			case 'category-tags':
				navInfo['label'] = 'category tags';
				navInfo['module'] = 'Category Tags';
				navInfo['routerLink'] = '/category-tags';
				break;
			case 'customer':
				navInfo['label'] = 'customers';
				navInfo['module'] = 'Customers';
				navInfo['icon'] = 'icon-customers';
				navInfo['routerLink'] = '/customers';
				break;
			case 'customer-list':
				navInfo['module'] = 'Customers';
				navInfo['label'] = 'customer list';
				navInfo['routerLink'] = '/customers';
				break;
			case 'meal-reference':
				navInfo['label'] = 'meal reference';
				navInfo['module'] = 'Meal Reference';
				navInfo['icon'] = 'icon-meal-preference';
				break;
			case 'eating-preference':
				navInfo['label'] = 'eating preference';
				navInfo['module'] = 'Eating Preference';
				navInfo['routerLink'] = '/eating-preferences';
				break;
			case 'diet-type':
				navInfo['label'] = 'diet type';
				navInfo['module'] = 'Diet Types';
				navInfo['routerLink'] = '/diet-types';
				break;
			case 'zone':
				navInfo['label'] = 'zones';
				navInfo['module'] = 'Zones';
				navInfo['icon'] = 'icon-zone';
				navInfo['routerLink'] = '/zones';
				break;
			case 'role':
				navInfo['label'] = 'roles';
				navInfo['module'] = 'Roles';
				navInfo['routerLink'] = '/roles';
				navInfo['icon'] = 'icon-permissions';
				break;
			case 'batch':
				navInfo['label'] = 'batch';
				navInfo['module'] = 'Batch';
				navInfo['icon'] = 'icon-batch';
				navInfo['routerLink'] = '/batch';
				break;
			case 'faq':
				navInfo['label'] = 'faq';
				navInfo['module'] = 'FAQ';
				navInfo['icon'] = 'icon-faq';
				navInfo['routerLink'] = '/faq';
				break;
			case 'feedback':
				navInfo['label'] = 'feedback';
				navInfo['module'] = 'Feedback';
				navInfo['icon'] = 'icon-feedback';
				navInfo['routerLink'] = '/feedbacks';
				break;
			case 'testimonial':
				navInfo['label'] = 'testimonials';
				navInfo['module'] = 'Testimonials';
				navInfo['icon'] = 'icon-testimonial';
				navInfo['routerLink'] = '/testimonials';
				break;
			case 'hub':
				navInfo['label'] = 'hubs';
				navInfo['module'] = 'Hubs';
				navInfo['icon'] = 'icon-hub';
				navInfo['routerLink'] = '/hubs';
				break;
			case 'discount':
				navInfo['label'] = 'discounts';
				navInfo['module'] = 'Discounts';
				navInfo['icon'] = 'icon-discount';
				navInfo['routerLink'] = '/discounts';
				break;
			case 'merchant-user':
				navInfo['label'] = 'merchant users';
				navInfo['module'] = 'Merchant Users';
				navInfo['icon'] = 'icon-merchant-user';
				navInfo['routerLink'] = '/merchant-users';
				break;
			case 'warehouse':
				navInfo['label'] = 'warehouses';
				navInfo['module'] = 'Warehouses';
				navInfo['icon'] = 'icon-warehouse';
				navInfo['routerLink'] = '/warehouses';
				break;
			case 'inventory':
				navInfo['label'] = 'inventory';
				navInfo['module'] = 'Inventory';
				navInfo['icon'] = 'icon-inventory';
				navInfo['routerLink'] = '/inventory';
				break;
			case 'promos':
				navInfo['label'] = 'promos';
				navInfo['module'] = 'Promos';
				navInfo['icon'] = 'icon-promo';
				navInfo['routerLink'] = '/promos';
				break;
			case 'promo-code':
				navInfo['module'] = 'Promos';
				navInfo['label'] = 'promo code';
				navInfo['routerLink'] = '/promos';
				break;
			case 'employee':
				navInfo['label'] = 'employees';
				navInfo['module'] = 'Employees';
				navInfo['icon'] = 'icon-employee';
				navInfo['routerLink'] = '/employees';
				break;
			case 'menu-view':
				navInfo['label'] = 'menu view';
				navInfo['module'] = 'Menu View';
				navInfo['icon'] = 'icon-menu-view';
				navInfo['routerLink'] = '/menu-view';
				break;
			case 'configurations':
				navInfo['icon'] = 'icon-settings';
				navInfo['label'] = 'configuration';
				navInfo['module'] = 'Configurations';
				break;
			case 'global-settings':
				navInfo['label'] = 'global settings';
				navInfo['module'] = 'Global Settings';
				navInfo['routerLink'] = '/global-settings';
				break;
			case 'fcm-files':
				navInfo['label'] = 'FCM files';
				navInfo['module'] = 'FCM Files';
				navInfo['routerLink'] = '/fcm-files';
				break;
			case 'support-login':
				navInfo['label'] = 'support login';
				navInfo['module'] = 'Support Login';
				navInfo['routerLink'] = '/support-login';
				break;
			case 'site-map':
				navInfo['label'] = 'site map';
				navInfo['module'] = 'Site Map';
				navInfo['routerLink'] = '/site-map';
				break;
			case 'home-setting':
				navInfo['label'] = 'home settings';
				navInfo['module'] = 'Home Settings';
				navInfo['routerLink'] = '/home-settings';
				break;
			case 'meta-title':
				navInfo['label'] = 'meta data';
				navInfo['module'] = 'Meta Data';
				navInfo['routerLink'] = '/meta-data';
				break;
			case 'craving-of-the-week':
				navInfo['icon'] = 'icon-orders-2';
				navInfo['module'] = 'Craving Of Week';
				navInfo['label'] = 'craving of the week';
				navInfo['routerLink'] = '/craving-of-week';
				break;
			case 'reports':
				navInfo['label'] = 'reports';
				navInfo['module'] = 'Report';
				navInfo['icon'] = 'icon-report';
				navInfo['routerLink'] = '/reports';
				break;
			case 'sales-reports':
				navInfo['label'] = 'sales report';
				navInfo['module'] = 'Sales Report';
				navInfo['routerLink'] = '/sales-report';
				break;
			case 'order-reports':
				navInfo['label'] = 'order report';
				navInfo['module'] = 'Order Report';
				navInfo['routerLink'] = '/order-report';
				break;
			case 'customer-reports':
				navInfo['label'] = 'customer report';
				navInfo['module'] = 'Customer Report';
				navInfo['routerLink'] = '/customer-report';
				break;
			case 'accounting-reports':
				navInfo['label'] = 'accounting report';
				navInfo['module'] = 'Accounting Report';
				navInfo['routerLink'] = '/accounting-report';
				break;
			default:
				break;
		}

		return navInfo;
	}
}
